.navbar-color {
    background-color: #333333;
    height: 90px;
}

.logo {
    img {
        width: 4rem;
        height: auto;
    }
}

.nav-link .nav-item {
    color: white;
}
